<template>
  <div class="info">
    <el-form>
      <div class="query">
        <div class="item">
          <span class="key">老人信息:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">工单编号:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">联系电话:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">服务项目:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">所属县区:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">所属街道:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">所属社区:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">工单状态:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="key">下单人:</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item item1">
          <span class="key">下单时间:</span>
          <el-date-picker class="margin" type="date" placeholder="选择日期">
          </el-date-picker>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <el-button type="primary">查询</el-button>
          <el-button type="primary">重置</el-button>
        </div>
      </div>
    </el-form>
    <div class="add">
      <el-button size="mini" type="primary">新增</el-button>
      <el-button size="mini" type="primary">编辑</el-button>
      <el-button size="mini" type="primary">导出数据</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border height="100%" >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="工作编码"></el-table-column>
      <el-table-column label="老人姓名"></el-table-column>
      <el-table-column label="操作" width="150">
        <!-- slot-scope="scope" -->
        <template slot-scope="scope">
          <div class="flex" v-if="scope.row.type === '1'">
            <div class="view">
              <span class="span">
                <span class="el-icon-s-order"></span>
              </span>
              <span>接单</span>
            </div>
            <div class="view">
              <span class="icon">
                <span class="el-icon-arrow-right"></span>
              </span>
              <span>改派</span>
            </div>
          </div>
          <div class="flex" v-else-if="scope.row.type === '2'">
            <div class="view">
              <span class="icon">
                <span class="el-icon-caret-right"></span>
              </span>
              <span>开始服务</span>
            </div>
            <div class="view">
              <span class="icon red">
                <span class="el-icon-close"></span>
              </span>
              <span class="font">取消</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="工作状态"></el-table-column>
      <el-table-column label="工作等级"></el-table-column>
      <el-table-column label="服务人员"></el-table-column>
      <el-table-column label="所属县区"></el-table-column>
      <el-table-column label="所属街道"></el-table-column>
      <el-table-column label="老人号码"></el-table-column>
      <el-table-column label="老人地址"></el-table-column>
      <el-table-column label="服务项目"></el-table-column>
    </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }]
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  width: 100%;
  height: 100%;

  .query {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    // padding-left: 30px;
    // box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      height: 38px;
      margin-bottom: 20px;
      color: #000;
      font-size: 18px;

      .key {
        width: 82px;
        margin-right: 10px;
      }

      .el-input,
      .el-date-picker {
        width: 156px;
        height: 38px;
        line-height: 38px;
      }

      .margin {
        margin-right: 30px;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .add {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
  }

  .table {
    width: 100%;
    height: calc(100% - 320px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;

    span {
      cursor: pointer;
      white-space: nowrap;
    }

    .view {
      display: flex;
      align-items: center;
    }

    .span {
      margin-right: 6px;
      font-size: 24px;
    }

    .icon {
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin-right: 6px;
      padding-left: 1px;
      font-weight: bolder;
      box-sizing: border-box;
      text-align: center;
      background-color: #01EEFD;
      border-radius: 50%;
      color: #000;
    }

    .red {
      background-color: #FF0000;
    }

    .font {
      color: #FF0000;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
