<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>新增房间</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="info">
            <div class="info-line">
              <span>房间号</span>
              <el-input v-model="params.fjh"></el-input>
            </div>
            <div class="info-line">
              <span>房间类型</span>
              <el-select v-model="params.fjlxbm" placeholder="请选择" @change="fjChange">
                <el-option v-for="item in fjs" :key="item.id" :label="item.val" :value="item.type">
                </el-option>
              </el-select>
            </div>
            <div class="info-line" v-if="showQt">
              <span>房间类型描述：</span>
              <el-input v-model="params.fjlxms"></el-input>
            </div>
            <div class="info-line">
              <span>所属楼层</span>
              <el-select v-model="params.louce" placeholder="请选择" @change="lcChange">
                <el-option v-for="item in lcs" :key="item.value" :label="item.louce" :value="item.louceid">
                </el-option>
              </el-select>
            </div>
            <div class="info-line">
              <span>床位数</span>
              <el-input v-model="params.cws"></el-input>
            </div>
            <div class="info-line">
              <span>紧急呼叫设备编号</span>
              <el-input placeholder="请输入紧急呼叫设备编号" v-model="params.sossbbh">
              </el-input>
            </div>
            <div class="info-line">
              <span>睡眠监测设备编号</span>
              <el-input placeholder="请输入睡眠监测设备编号" v-model="params.sjmcsbbh">
              </el-input>
            </div>
            <div class="info-line">
              <span>跌倒监测设备编号</span>
              <el-input placeholder="请输入跌倒监测设备编号" v-model="params.fddsbbh">
              </el-input>
            </div>
            <div class="info-line">
              <span>其他设备编号</span>
              <el-input placeholder="请输入其他设备编号" v-model="params.qtsbbh">
              </el-input>
            </div>
            <div class="info-line">
              <span>房间图片</span>
              <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    lch: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      input: '',
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      imageUrl: '',
      params: {
        louce: '',
        louceid: '',
        glfjs: '',
        lccjr: '',
        lccjsj: '',
        ssjgbh: '',
        ssjgmc: '',
        jgbh: '',
        jgmc: '',
        fjh: '',
        fjid: '',
        fjlx: '',
        fjlxbm: '',
        cws: '',
        rzlr: '',
        fjcjr: '',
        fjcjsj: '',
        cwh: '',
        cwhid: '',
        cwyjg: '',
        cwcjr: '',
        cwcjsj: '',
        cwzt: '',
        fjlxms: '',
        sossbbh: '',
        sjmcsbbh: '',
        fddsbbh: '',
        qtsbbh: ''
      },
      fjs: [],
      lcs: [],
      showQt: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getFjList()
    this.getLcList()
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    },
    getDate () {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let f = date.getMinutes()
      f = f < 10 ? '0' + f : f
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      return `${year}-${month}-${day} ${h}:${f}:${s}`
      // return `${year}-${month}-${day}`
    },
    async getFjList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-118011488
      try {
        const { data: res } = await this.$http.post('/api/jkct/app/finddict', { type: 'zhyyfjlx' })
        console.log('房间类型', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.fjs = res.data
        this.fjs.push(
          {
            createTime: '2023-10-19 12:11:17',
            id: 14,
            k: '01',
            type: 'qita',
            updateTime: '2023-10-19 12:11:17',
            val: '其他'
          }
        )
      } catch (error) {
        console.log('房间类型失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    fjChange (e) {
      console.log()
      this.params.fjlx = this.fjs.filter(item => item.type === e)[0].val
      if (this.fjs.filter(item => item.type === e)[0].val === '其他') {
        this.showQt = true
      } else {
        this.showQt = false
        this.params.fjlxms = ''
      }
    },
    async getLcList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'lc',
          louceid: '',
          cwid: '',
          fjid: ''
        })
        console.log('楼层列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.lcs = res.data
      } catch (error) {
        console.log('楼层列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    lcChange (e) {
      // console.log(e)
      this.params.louceid = e
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-111166388
      try {
        if (this.params.fjh === '') return this.$message.error('请输入房间号')
        if (this.params.fjlx === '') return this.$message.error('请选择房间类型')
        if (this.params.cws === '') return this.$message.error('请输入床位数')
        this.params.lccjr = this.loginInfo.usercode || ''
        this.params.lccjsj = this.getDate()
        this.params.ssjgbh = this.loginInfo.jgbh || ''
        this.params.ssjgmc = this.loginInfo.jgmc || ''
        this.params.jgbh = this.loginInfo.jgbh || ''
        this.params.jgmc = this.loginInfo.jgmc || ''
        this.params.fjid = this.params.fjh || ''
        this.params.louceid = this.lch || ''
        console.log('请求参数', this.params)
        const { data: res } = await this.$http.post('/zhyyapi/zhyljcszcwgllcfj', this.params)
        console.log('房间管理', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.$message.success('添加成功')
        this.$emit('close', 'loading')
      } catch (error) {
        console.log('房间管理失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1042px;
    height: 850px;

    .popup-title {
      width: 240px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 1042px;
      height: 800px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .info {
          display: flex;
          flex-direction: column;
          width: 100%;

          .info-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-top: 14px;
            color: #01EEFD;
            font-size: 18px;

            .avatar-uploader {
              border: 1px dashed #d9d9d9;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
              background-color: #fff;
            }

            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 178px;
              height: 178px;
              line-height: 178px;
              text-align: center;
            }

            .avatar {
              width: 178px;
              height: 178px;
              display: block;
            }

            span {
              width: 200px;
            }

            .el-select {
              width: calc(100% - 210px);
            }

            .el-input {
              width: calc(100% - 210px);
            }

            .el-textarea {
              width: 800px;
            }

            /deep/.el-textarea__inner {
              width: 800px;
              height: 104px;
              resize: none;
            }
          }

          .info-line:nth-child(3) {
            align-items: initial;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
