<template>
  <div class="page">
    <div class="page-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="page-right">
      <div class="tab">
        <span :class="{ span: tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{ item }}</span>
      </div>
      <div class="scroll">
        <!-- 服务工单管理 -->
        <Run v-if="tabIndex === 0"></Run>
        <!-- 护理计划 -->
        <Nursing v-else-if="tabIndex === 1"></Nursing>
        <!-- 用药计划 -->
        <medication v-else-if="tabIndex === 2"></medication>
        <!-- 康复计划 -->
        <Recovery v-else-if="tabIndex === 3"></Recovery>
      </div>
    </div>
    <BasicInfoPopup v-if="showBasicInfoPopup" @close="closeBasicInfoPopup"></BasicInfoPopup>
  </div>
</template>

<script>
import Run from './run/run.vue'
import Nursing from './nursing/nursing.vue'
import medication from './medication/medication.vue'
import Recovery from './recovery/recovery.vue'
import BasicInfoPopup from '../../monitoring/basicInfoPopup.vue'
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
export default {
  components: {
    Run,
    Nursing,
    medication,
    Recovery,
    BasicInfoPopup,
    UserBasicInfo
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['服务工单管理', '护理计划', '用药计划', '康复计划']),
      showBasicInfoPopup: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openBasicInfoPopup () {
      this.showBasicInfoPopup = true
    },
    closeBasicInfoPopup () {
      this.showBasicInfoPopup = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  display: flex;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: #fff;

  .page-left {
    width: 270px;
    height: 100%;

    .title {
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding-left: 34px;
      padding-top: 5px;
      box-sizing: border-box;
      background: url('../../img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: 752px;
      margin-top: 5px;
      border-radius: 6px;
      background-color: rgba(29, 64, 112, .33);

      .list {
        display: flex;
        align-items: center;
        width: 610px;
        height: 50px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;

        div {
          width: 60px;
          text-align: center;
        }

        .flex2 {
          width: 100px;
        }

        .flex3 {
          width: 150px;
        }

        .flex4 {
          width: 300px;
        }
      }

      &::-webkit-scrollbar {
        width: .125rem;
        height: .125rem;
        background-color: transparent;
        border-radius: .125rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, .4);
        border-radius: .125rem;
      }

      .color {
        position: sticky;
        top: 0;
        left: 0;
        padding: 4px 0;
        z-index: 88;
        background-color: rgba(14, 40, 75, 1);
      }

      .movetop {
        // animation: moveani var(--time) infinite linear normal;
      }

      @keyframes moveani {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(var(--moveTop));
        }
      }

      .movetop:hover {
        animation-play-state: paused;
      }
    }
  }

  .page-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    box-sizing: border-box;

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #000;

      span {
        height: 38px;
        line-height: 38px;
        margin-right: 16px;
        padding: 0 9px;
        color: #fff;
        font-size: 22px;
        background-color: #D5D5D5;
        cursor: pointer;
      }

      .span {
        background-color: #409EFF;
      }
    }

    .scroll {
      width: 100%;
      height: calc(100% - 70px);
      margin-top: 10px;
    }
  }
}
</style>
