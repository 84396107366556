<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>删除确认</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="tip">
            <span>是否确认删除该楼层</span>
            <span class="span">删除后将不可恢复</span>
          </div>
          <!-- <div class="tip">
            <span>该楼层关联了若干房间</span>
            <span class="span">暂不支持删除操作</span>
          </div> -->
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input: '',
      params: {
        id: 0
      }
    }
  },
  created () {
    console.log(this.$parent.selectInfo)
    this.params.id = this.$parent.selectInfo.id || ''
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async submit () {
      try {
        console.log('请求参数', this.params)
        const response = await this.$http.post('/ykdzhylcwgls/delete', this.params)
        const res = response.data
        console.log(res)
        this.$message.success('删除成功')
        this.$parent.getList()
        this.$emit('close')
      } catch (error) {
        console.log('楼层删除失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 859px;
    height: 264px;

    .popup-title {
      width: 147px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 800px;
      height: 223px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px 10px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .tip {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #01EEFD;
          font-size: 18px;

          .span {
            margin-top: 6px;
            font-size: 16px;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
