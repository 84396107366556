<template>
  <div class="run">
    <div class="run-left">
      <div class="title">
        <span>房间列表</span>
      </div>
      <div class="scroll">
        <div class="scroll-item" v-for="(item, index) in list" :key="index">
          <div class="scroll-title" @click="sel(item)">
            <span>{{ item.louce }}楼</span>
          </div>
          <div class="scroll-box">
            <div class="scroll-list" :class="{ 'scroll-active': selectIndex === (indexs + '' + index) }" v-for="(items, indexs) in item.lcfjlb" :key="indexs" @click="select(indexs + '' + index, items)">
              <span>{{ items.fjh }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="run-right">
      <div class="son-add">
      <div class="son-add-icon" @click="openAdd('1')">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <div class="table">
      <el-table :data="tableData" border height="100%" ref="table">
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="cwh" label="床位号">
      </el-table-column>
      <el-table-column prop="rzlr" label="入住老人">
      </el-table-column>
      <el-table-column prop="cwyjg" label="床位月价格">
      </el-table-column>
      <el-table-column prop="sossbbh" label="紧急呼叫设备编号">
      </el-table-column>
      <el-table-column prop="sjmcsbbh" label="睡眠监测设备编号">
      </el-table-column>
      <el-table-column prop="fddsbbh" label="跌倒监测设备编号">
      </el-table-column>
      <el-table-column prop="qtsbbh" label="其他设备编号">
      </el-table-column>
      <el-table-column prop="cwcjr" label="创建人">
      </el-table-column>
      <el-table-column prop="cwcjsj" label="创建时间">
      </el-table-column>
      <el-table-column prop="cwzt" label="状态">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <!-- <div class="flex">
            <span @click="openAdd(scope.row)">编辑</span>
            <span @click="openDelete">删除</span>
          </div> -->
          <el-button size="mini" type="primary" @click="openUpdate(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="openDelete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total">
          </el-pagination>
    </div>

    <!-- 编辑 -->
    <Add v-if="showAdd" :fjh="selectFjh" :info="selectInfo" @close="closeAdd"></Add>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <!-- 配置护理服务 -->
    <Set v-if="showSet" @close="closeSet"></Set>
    <update  v-if="showUpdate" @close="closeUpdate"></update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Add from './add.vue'
import Delete from './delete.vue'
import Set from './set.vue'
import update from './update.vue'
export default {
  components: {
    Add,
    Delete,
    Set,
    update
  },
  data () {
    return {
      tableData: [],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showUpdate: false,
      showDelete: false,
      showSet: false,
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      selectFjh: '',
      params: {
        louce: '',
        louceid: '',
        glfjs: '',
        lccjr: '',
        lccjsj: '',
        ssjgbh: '',
        ssjgmc: '',
        jgbh: '',
        jgmc: '',
        fjh: '',
        fjid: '',
        fjlx: '',
        fjlxbm: '',
        cws: '',
        rzlr: '',
        fjcjr: '',
        fjcjsj: '',
        cwh: '',
        cwhid: '',
        cwyjg: '',
        cwcjr: '',
        cwcjsj: '',
        cwzt: '',
        name: '',
        sfzh: ''
      },
      list: [],
      louceid: '',
      fjid: '',
      selectInfo: {}
    }
  },
  created () {
    // this.getInfo()
    this.getList()
    this.getLcList()
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    // 楼层，房间，床位列表
    async getList () {
      // 接口地址 https://app.apifox.com/link/project/2726972/apis/api-116437341
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          lytype: '3',
          louceid: this.louceid,
          fjid: this.fjid,
          cwhid: ''
        }
        const { data: res } = await this.$http.get('/ykdzhylcwgls', { params: obj })
        console.log('楼层，房间，床位列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          if (this.$refs.table) {
            this.$refs.table.bodyWrapper.scrollTop = 0
          }
        })
      } catch (error) {
        console.log('楼层，房间，床位列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    getDate () {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let f = date.getMinutes()
      f = f < 10 ? '0' + f : f
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      return `${year}-${month}-${day} ${h}:${f}:${s}`
      // return `${year}-${month}-${day}`
    },
    // async getInfo () {
    //   // 接口地址 https://app.apifox.com/project/2726972/apis/api-116488628
    //   try {
    //     this.params.lccjr = this.loginInfo.usercode
    //     this.params.lccjsj = this.getDate()
    //     this.params.ssjgbh = this.loginInfo.jgbh
    //     this.params.ssjgmc = this.loginInfo.jgmc
    //     this.params.jgbh = this.loginInfo.jgbh
    //     this.params.jgmc = this.loginInfo.jgmc
    //     console.log('请求参数', this.params)
    //     const { data: res } = await this.$http.post('/zhyyapi/zhyljcszcwgllcfjcwlb', this.params)
    //     console.log('楼层房间列表', res)
    //     if (res.code !== 0) return this.$message.error(res.msg || res.message)
    //     this.list = res.data
    //   } catch (error) {
    //     console.log('楼层房间列表失败', error)
    //     this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
    //   }
    // },
    async getLcList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'fjall',
          louceid: '',
          cwid: '',
          fjid: ''
        })
        console.log('房间列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list = res.data
      } catch (error) {
        console.log('房间列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    sel (obj) {
      this.louceid = obj.louceid
      this.fjid = ''
      this.getList()
    },
    select (index, obj) {
      this.selectIndex = index
      this.louceid = obj.louceid
      this.fjid = obj.fjid
      this.selectFjh = obj.fjh
      this.getList()
    },

    tabClick (index) {
      this.tabIndex = index
    },
    openAdd (obj) {
      if (obj !== '1') {
        this.selectInfo = obj
      }
      this.showAdd = true
    },
    closeAdd (e) {
      this.showAdd = false
      if (e === 'loading') {
        this.getList()
      }
      this.selectInfo = {}
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openUpdate (info) {
      console.log(info)
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #fff;

  .run-left {
    width: 256px;
    height: 100%;
    padding: 0 10px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .title {
      height: 46px;
      line-height: 46px;
      color: #fff;
      font-size: 18px;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 46px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .scroll-title {
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }

      .scroll-list {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .scroll-box {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .scroll-active {
        color: #01EEFD;
      }
    }
  }

  .run-right {
    width: calc(100% - 270px);
    height: 100%;
    margin-left: 10px;
  }

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }

    .son-item:first-child {
      margin-right: 40px;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 140px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
