<template>
  <div class="run">

    <div class="son-add">
      <div class="son-add-icon" @click="openAdd">
        <span class="el-icon-plus"></span>
      </div>
    </div>

    <div class="table">
      <el-table :data="tableData" border height="100%" ref="table">
      <el-table-column label="序号" type="index" width="100" >
      </el-table-column>
      <el-table-column prop="louce" label="楼层">
      </el-table-column>
      <el-table-column label="关联房间数">
        <template slot-scope="scope">
          <div class="text" @click="tab">
            <span>{{ scope.row.glfjs }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="lccjr" label="创建人">
      </el-table-column>
      <el-table-column prop="lccjsj" label="创建时间">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <!-- <div class="flex">
            <span @click="openAdd">编辑</span>
            <span @click="openDelete">删除</span>
          </div> -->
          <el-button size="mini" type="primary" @click="openUpdate(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="openDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total">
          </el-pagination>
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <update v-if="showUpdate" :selectInfo="selectInfo" @close="closeUpdate"></update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Update from './update.vue'
import Add from './add.vue'
import Delete from './delete.vue'
export default {
  components: {
    Add,
    Delete,
    Update
  },
  data () {
    return {
      tableData: [],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: 0,
      showAdd: false,
      showDelete: false,
      showUpdate: false,
      showSee: false,
      selectInfo: {},
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
  },
  methods: {
    // 楼层，房间，床位列表
    async getList () {
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          lytype: '1',
          fjid: '',
          cwhid: ''
        }
        const { data: res } = await this.$http.get('/ykdzhylcwgls', { params: obj })
        console.log('楼层，房间，床位列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          if (this.$refs.table) {
            this.$refs.table.bodyWrapper.scrollTop = 0
          }
        })
      } catch (error) {
        console.log('楼层，房间，床位列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tab () {
      this.$router.push('/smartelderlycare/basicset/bed/tab2')
    },
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd (e) {
      this.showAdd = false
      if (e === 'loading') {
        this.getList()
      }
    },
    openDelete (info) {
      this.selectInfo = info
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openUpdate (info) {
      console.log('info---------', info)
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 140px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
