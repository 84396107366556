<template>
  <div class="set">
    <div class="set-head">
      <span>配置护理服务</span>
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search" class="input"></el-input>
    </div>
    <div class="set-info">
      <span class="set-status">待审批</span>
      <div class="set-info-title">
        <span>护理等级：重度护理</span>
      </div>
      <div class="set-line">
        <span>护理服务</span>
      </div>
      <div class="set-line">
        <span>护理项目：</span>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="1">晨起</el-checkbox>
          <el-checkbox label="2">娱乐</el-checkbox>
          <el-checkbox label="3">就寝</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="set-line">
        <span>服务配置：</span>
      </div>
    </div>
    <div class="set-title">
      <span>晨起</span>
    </div>
    <div class="set-table">
      <div class="set-thead">
        <span>护理服务</span>
      </div>
      <div class="set-tr set-tr1">
        <span>辅助起床</span>
      </div>
      <div class="set-tr">
        <span>辅助就餐</span>
      </div>
    </div>
    <div class="set-title">
      <span>娱乐</span>
    </div>
    <div class="set-table">
      <div class="set-thead">
        <span>护理服务</span>
      </div>
      <div class="set-tr set-tr1">
        <span>辅助起床</span>
      </div>
      <div class="set-tr">
        <span>辅助就餐</span>
      </div>
    </div>
    <div class="set-btn">
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checkList: []
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('close', this.input)
    }
  }
}
</script>

<style lang="less" scoped>
.set {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  background-color: #081C38;
  box-sizing: border-box;

  .set-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;

    .input {
      width: 246px;

      /deep/.el-input__inner {
        border: none;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        color: #fff;
        font-size: 18px;
      }

      /deep/.el-input__inner::-webkit-input-placeholder {
        color: #fff;
      }

      /deep/.el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .set-info {
    position: relative;
    display: flex;
    flex-direction: column;

    .set-status {
      position: absolute;
      top: 41px;
      right: 11px;
      color: #FD0101;
      font-size: 20px;
      font-weight: 600;
    }

    .set-info-title {
      height: 65px;
      line-height: 65px;
      color: #01EEFD;
      font-size: 20px;
    }

    .set-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 36px;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 16px;

      /deep/.el-checkbox__inner {
        background-color: transparent;
      }

      /deep/.el-checkbox__label {
        color: #fff;
        font-size: 16px;
      }
    }
  }

  .set-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
  }

  .set-table {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 732px;
    margin-top: 20px;
    border: 4px #073F73 solid;
    box-sizing: border-box;

    .set-thead {
      width: 100%;
      height: 44px;
      line-height: 44px;
      padding-left: 70px;
      background-color: #024276;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 17px;
    }

    .set-tr {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 70px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 17px;
    }

    .set-tr1 {
      border-bottom: 1px solid #01EEFD;
    }
  }

  .set-btn {
    position: absolute;
    left: 70px;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 140px;
    margin: 0 auto;
  }

  .el-button {
    border-radius: 0;
    border: none;
    background-color: #33465C;
    color: #fff;
  }

  .el-button:nth-child(2) {
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
  }
}
</style>
