import { render, staticRenderFns } from "./nursing.vue?vue&type=template&id=2ec53286&scoped=true&"
import script from "./nursing.vue?vue&type=script&lang=js&"
export * from "./nursing.vue?vue&type=script&lang=js&"
import style0 from "./nursing.vue?vue&type=style&index=0&id=2ec53286&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec53286",
  null
  
)

export default component.exports