<template>
  <div class="run">
    <div class="run-left">
      <div class="title">
        <span>楼层列表</span>
      </div>
      <div class="scroll">
        <div class="scroll-item" v-for="(item, index) in list" :key="index">
          <div class="scroll-title" :class="{ 'scroll-active': selectIndex === index }" @click="sel(index, item)">
            <span>{{ item.louce }}楼</span>
          </div>
          <!-- <div class="scroll-box">
            <div class="scroll-list" :class="{ 'scroll-active': selectIndex === indexs }" v-for="(items, indexs) in item.lcfjlb" :key="indexs" @click="select(indexs, items)">
              <span>{{ items.fjh }}</span>
            </div>
          </div> -->
        </div>

        <!-- <div class="scroll-list">
          <span>401</span>
        </div>
        <div class="scroll-list scroll-active">
          <span>402</span>
        </div>
        <div class="scroll-list">
          <span>403</span>
        </div>
        <div class="scroll-title">
          <span>5楼</span>
        </div>
        <div class="scroll-list">
          <span>501</span>
        </div>
        <div class="scroll-list">
          <span>502</span>
        </div>
        <div class="scroll-list">
          <span>503</span>
        </div> -->
      </div>
    </div>
    <div class="run-right">
      <div class="son-add">
      <div class="son-add-icon" @click="openAdd('')">
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border ref="table">
      <el-table-column label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column prop="fjh" label="房间号">
      </el-table-column>
      <el-table-column prop="louce" label="楼层">
      </el-table-column>
      <el-table-column prop="fjlx" label="房间类型">
      </el-table-column>
      <el-table-column prop="cws" label="床位数">
      </el-table-column>
      <el-table-column prop="rzlr" label="入住老人">
      </el-table-column>
      <el-table-column prop="fjcjsj" label="创建时间">
      </el-table-column>
      <el-table-column prop="fjcjr" label="创建人">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <!-- <div class="flex">
            <span @click="openAdd">编辑</span>
            <span @click="openDelete">删除</span>
          </div> -->
          <el-button size="mini" type="primary" @click="openUpdate(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="openDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper" :total="query1.total">
          </el-pagination>
    </div>
    <Add v-if="showAdd" :lch="louceid" @close="closeAdd"></Add>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <update v-if="showUpdate" :selectInfo="selectInfo" @close="closeUpdate"></update>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Update from './update.vue'
import Add from './add.vue'
import Delete from './delete.vue'
export default {
  components: {
    Add,
    Delete,
    Update
  },
  data () {
    return {
      list: [],
      tableData: [],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      currentPage4: 4,
      tabIndex: 0,
      selectIndex: -1,
      showAdd: false,
      showDelete: false,
      showSee: false,
      showUpdate: false,
      selectInfo: {},
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      louceid: '',
      fjid: ''
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList()
    this.getLcList()
  },
  methods: {
    // 楼层，房间，床位列表
    async getList () {
      // 接口地址 https://app.apifox.com/link/project/2726972/apis/api-116437341
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: this.query1.pageSize + '',
          ssjgbh: this.loginInfo.jgbh,
          lytype: '2',
          louceid: this.louceid,
          fjid: this.fjid,
          cwhid: ''
        }
        const { data: res } = await this.$http.get('/ykdzhylcwgls', { params: obj })
        console.log('楼层，房间，床位列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data
        this.query1.total = res.recordsTotal
        this.$nextTick(() => {
          if (this.$refs.table) {
            this.$refs.table.bodyWrapper.scrollTop = 0
          }
        })
      } catch (error) {
        console.log('楼层，房间，床位列表请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getLcList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-117949643
      try {
        const { data: res } = await this.$http.post('/zhyyapi/findlcwsfjlb', {
          ssjgbh: this.loginInfo.jgbh,
          jgbh: this.loginInfo.jgbh,
          lytype: 'lc',
          louceid: '',
          cwid: '',
          fjid: ''
        })
        console.log('楼层列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list = res.data
      } catch (error) {
        console.log('楼层列表失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick (index) {
      this.tabIndex = index
    },
    sel (index, obj) {
      this.selectIndex = index
      this.louceid = obj.louceid
      this.fjid = ''
      this.getList()
    },
    select (index, obj) {
      this.selectIndex = index
      this.louceid = obj.louceid
      this.fjid = obj.fjid
      this.getList()
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd (e) {
      this.showAdd = false
      if (e === 'loading') {
        this.getList()
      }
    },
    openDelete (info) {
      console.log('----------', info)
      this.selectInfo = info
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openUpdate (info) {
      console.log('info+++++++', info)
      this.selectInfo = info
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.run {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #fff;

  .run-left {
    width: 256px;
    height: 100%;
    padding: 0 10px;
    background-color: #0C4969;
    border-radius: 6px;
    box-sizing: border-box;

    .title {
      height: 46px;
      line-height: 46px;
      color: #fff;
      font-size: 18px;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 46px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .scroll-title {
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }

      .scroll-list {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }

      .scroll-box {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .scroll-active {
        color: #01EEFD;
      }
    }
  }

  .run-right {
    width: calc(100% - 270px);
    height: 100%;
    margin-left: 10px;
  }

  .son-tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    .son-item {
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      text-align: center;
      background-color: #284867;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }

    .son-active {
      background-color: #04B4D0;
    }

    .son-item:first-child {
      margin-right: 40px;
    }
  }

  .son-tab1 {
    border: none;
  }

  .son-add {
    display: flex;
    align-items: center;
    height: 50px;

    .son-add-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #409EFF;
      border-radius: 50%;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .table {
    width: 100%;
    height: calc(100% - 140px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    span {
      cursor: pointer;
    }
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
