<template>
  <div class="page">
    <div class="page-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="page-right">
      <div class="tab">
        <span class="span">康复计划</span>
      </div>
      <div class="scroll">
        <div class="recovery">
          <div class="title">
            <span>老人康复方案计划及训练记录</span>
          </div>
          <div class="name">
            <span>姓名：程霞</span>
            <span>编号：</span>
          </div>
          <div class="line line1">
            <span>姓名：</span>
            <span>性别：</span>
            <span>年龄：</span>
          </div>
          <div class="line line2">
            <span class="span">老人初始状态：</span>
            <el-checkbox-group v-model="checkList">
              <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="line">
            <div class="line-title">
              <span>康复内容(依据初次评估结果确定重点训练的项目为：)</span>
            </div>
            <div class="line2 margin">
              <span class="span">一、肢体康复:</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks1" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="line2 margin">
              <span class="span">二、心理康复:</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks2" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="line2 margin">
              <span class="span">三、分项康复:</span>
            </div>
            <div class="line2 margin">
              <span class="span">1.运动能力：</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks3" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="line2 margin">
              <span class="span">2.感知能力：</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks4" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="line2 margin">
              <span class="span">3.认知能力：</span>
              <div class="check">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox v-for="(item, index) in checks5" :key="index" :label="index + ''">{{ item
                  }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="line2 margin">
              <span class="span">4.语言交往能力：</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks6" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="line2 margin">
              <span class="span">5.生活自理能力：</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks7" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="line2 margin">
              <span class="span">6.社会适应能力:</span>
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks8" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="line line2">
            <span class="span">辅助工具</span>
            <div class="check">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks9" :key="index" :label="index + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="line line2">
            <span class="span">康复计划：</span>
            <el-checkbox-group v-model="checkList">
              <el-checkbox v-for="(item, index) in checks10" :key="index" :label="index + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
            <span class="color">时间：</span>
            <el-checkbox-group v-model="checkList">
              <el-checkbox v-for="(item, index) in checks11" :key="index" :label="index + ''">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="table table1">
            <div class="thead">
              <span class="td">日期</span>
              <span class="td">上午</span>
              <span class="td">下午</span>
            </div>
            <div class="tscroll">
              <div class="tscroll-box">
                <div class="tr" v-for="(item, index) in 30" :key="index">
                  <span class="td">{{ item }}</span>
                  <span class="td">{{ item }}</span>
                  <span class="td">{{ item }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="line line3">
            <span>每月康复结果评估：</span>
          </div>
          <div class="bottom">
            <span>护理员签名：</span>
            <span>审核人签名：</span>
            <span>医师签名：</span>
          </div>
        </div>
      </div>
    </div>
    <BasicInfoPopup v-if="showBasicInfoPopup" @close="closeBasicInfoPopup"></BasicInfoPopup>
  </div>
</template>

<script>
import BasicInfoPopup from '../../monitoring/basicInfoPopup.vue'
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
export default {
  components: {
    BasicInfoPopup,
    UserBasicInfo
  },
  data () {
    return {
      checkList: ['0', '1'],
      checks: Object.freeze(['高血压', '糖尿病', '心脑血管疾病', '老年痴呆', '中风偏瘫', '行动迟缓', '口齿不清', '耳聋', '肢体受损 ', '卧床', '其他']),
      checks1: Object.freeze(['手关节及肌力', '脚关节及肌力 ', '体位转移', '步行', '站立']),
      checks2: Object.freeze(['沟通交流', '益智游戏 ', '娱乐活动', '亲情关怀']),
      checks3: Object.freeze(['穿针引线', '穿珠子', '折纸', '跳绳', '拍球', '俯卧撑', '引体向上']),
      checks4: Object.freeze(['注视物体', '追视移动物体', '分辨味道', '分辨气味', '分辨常见生活环境声音']),
      checks5: Object.freeze(['认识物体', '物品配对', '认识物体关系', '认识颜色', '认识形状', '分辨颜色', '认识蔬菜,水果等食品', '知道天气情况', '点数', '认识时间', '认识钱币']),
      checks6: Object.freeze(['知道自己、父母的名字', '服从简单指令', '表达需求', '说简单的短句', '语言交流', '书写基本能力']),
      checks7: Object.freeze(['洗澡', '洗衣服', '洗被褥', '叠衣物', '整理床铺', '认识环境', '打扫卫生']),
      checks8: Object.freeze(['知道自己', '认识熟悉的人', '认识家庭环境', '知道居家安全', '认识公共设施', '参加集体活动']),
      checks9: Object.freeze(['助行器', '艾灸器', '上下肢脚踏车', '上肢牵引器', '肩关节太极揉推器', '脚踝关节矫正鞋', '手脚健腹器', '跳绳', '神灯理疗仪', '跑步机', '握力器/球', '手指木插板康复器', '神经触觉康复球', '足底穴位按摩康复器', '手功能螺母康复器', '棋牌类']),
      checks10: Object.freeze(['每日一次', '每日两次', '每周一次']),
      checks11: Object.freeze(['五分钟', '十分钟', '十五分钟']),
      showBasicInfoPopup: false
    }
  },
  methods: {
    openBasicInfoPopup () {
      this.showBasicInfoPopup = true
    },
    closeBasicInfoPopup () {
      this.showBasicInfoPopup = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  display: flex;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: #fff;

  .page-left {
    width: 270px;
    height: 100%;

    .title {
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding-left: 34px;
      padding-top: 5px;
      box-sizing: border-box;
      background: url('../../img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: 752px;
      margin-top: 5px;
      border-radius: 6px;

      .list {
        display: flex;
        align-items: center;
        width: 610px;
        height: 50px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;

        div {
          width: 60px;
          text-align: center;
        }

        .flex2 {
          width: 100px;
        }

        .flex3 {
          width: 150px;
        }

        .flex4 {
          width: 300px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .color {
        position: sticky;
        top: 0;
        left: 0;
        padding: 4px 0;
        z-index: 88;
        background-color: rgba(14, 40, 75, 1);
      }

      .movetop {
        // animation: moveani var(--time) infinite linear normal;
      }

      @keyframes moveani {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(var(--moveTop));
        }
      }

      .movetop:hover {
        animation-play-state: paused;
      }
    }
  }

  .page-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    padding: 10px;
    margin-left: 10px;
    box-sizing: border-box;

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #000;

      span {
        height: 38px;
        line-height: 38px;
        margin-right: 16px;
        padding: 0 9px;
        color: #fff;
        font-size: 22px;
        background-color: #D5D5D5;
        cursor: pointer;
      }

      .span {
        background-color: #409EFF;
      }
    }

    .scroll {
      width: 100%;
      height: calc(100% - 70px);
      margin-top: 10px;

      .recovery {
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 100%;
        }

        .title {
          width: 100%;
          text-align: center;
          color: #000;
          font-size: 22px;
        }

        .name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-top: 12px;
          margin-bottom: 6px;
          color: #000;
          font-size: 22px;
        }

        .line {
          width: 100%;
          padding: 17px 11px;
          border: 1px solid #000;
          border-bottom: none;
          box-sizing: border-box;
          color: #000;
          font-size: 14px;

          .span {
            width: 128px;
          }

          .color {
            width: 60px;
            margin-left: 50px;
            color: #000;
          }

          .check {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: calc(100% - 128px);
            padding: 5px 0;

            .el-checkbox {
              margin-bottom: 8px;
            }
          }

          .line-title {
            width: 100%;
            margin-bottom: 6px;
            color: #000;
            font-size: 14px;
          }
        }

        .line1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 400px;
        }

        .line2 {
          display: flex;
        }

        .line3 {
          border-top: none;
          border-bottom: 1px solid #000;
        }

        .margin {
          margin-bottom: 8px;
        }

        .table {
          display: flex;
          align-items: center;
          width: 100%;
          height: 180px;
          border: 1px solid #000;
          border-right: none;
          border-bottom: none;
          box-sizing: border-box;

          .thead,
          .tr {
            display: flex;
            flex-direction: column;
            color: #000;
            font-size: 14px;

            .td {
              width: 60px;
              height: 60px;
              line-height: 60px;
              text-align: center;
              border: 1px solid #000;
              border-left: none;
              border-top: none;
              box-sizing: border-box;
            }
          }

          .tscroll {
            overflow-x: scroll;
            overflow-y: hidden;
            display: flex;
            width: calc(100% - 60px);
            height: 180px;

            &::-webkit-scrollbar {
              height: 0;
            }

            .tscroll-box {
              display: flex;
              width: 100%;
              height: 100%;
            }
          }

          .tr {
            color: #000;
          }
        }

        .table1 {
          border-right: 1px solid #000;
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 90px;
          padding-top: 10px;
          padding-left: 14px;
          padding-right: 600px;
          box-sizing: border-box;
          color: #000;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
