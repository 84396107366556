<template>
  <div class="info">
    <div class="add">
      <el-button size="mini" type="primary" @click="openAddNursing">新增</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" height="100%" border>
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="工作编码"></el-table-column>
      <el-table-column label="计划序号"></el-table-column>
      <el-table-column label="护理内容"></el-table-column>
      <el-table-column label="服务时间"></el-table-column>
      <el-table-column label="执行频率"></el-table-column>
      <el-table-column label="任务类型"></el-table-column>
      <el-table-column label="所属街道"></el-table-column>
      <el-table-column label="操作">
        <!-- slot-scope="scope" -->
        <template>
          <!-- <div class="flex">
            <span>编辑</span>
            <span>删除</span>
          </div> -->
          <el-button size="mini" type="primary">编辑</el-button>
          <el-button size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <AddNursing v-if="showAddNursing" @close="closeAddNursing"></AddNursing>
  </div>
</template>

<script>
import AddNursing from '../nursing/addNursing.vue'
export default {
  components: {
    AddNursing
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001'
      }],
      showAddNursing: false
    }
  },
  methods: {
    openAddNursing () {
      this.showAddNursing = true
    },
    closeAddNursing () {
      this.showAddNursing = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: relative;
  width: 100%;
  height: 100%;

  .query {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 30px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      height: 38px;
      margin-bottom: 20px;
      color: #04B4D0;
      font-size: 18px;

      .key {
        width: 82px;
        margin-right: 10px;
      }

      .el-input,
      .el-date-picker {
        width: 156px;
        height: 38px;
        line-height: 38px;
      }

      .margin {
        margin-right: 30px;
      }

      .el-button {
        width: 80px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        margin-right: 50px;
        padding: 0;
        border: 0;
        border-radius: 0;
        color: #fff;
        font-size: 18px;
        background-color: #D5D5D5;
      }

      .blue {
        background-color: #01EEFD;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .add {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
  }

  .table {
    width: 100%;
    height: calc(100% - 160px);
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #000;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    span {
      cursor: pointer;
    }

    .view {
      display: flex;
      align-items: center;
    }

    .span {
      margin-right: 6px;
      font-size: 24px;
    }

    .icon {
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin-right: 6px;
      padding-left: 1px;
      font-weight: bolder;
      box-sizing: border-box;
      text-align: center;
      background-color: #01EEFD;
      border-radius: 50%;
      color: #000;
    }

    .red {
      background-color: #FF0000;
    }

    .font {
      color: #FF0000;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
