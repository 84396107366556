<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>楼层编辑</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="info">
            <div class="info-line">
              <span>楼层号</span>
              <el-input placeholder="请输入楼层号" v-model="params.louce"></el-input>
            </div>
            <div class="info-line">
              <span>房间数</span>
              <el-input placeholder="请输入房间数" v-model="params.glfjs"></el-input>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="submit">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    selectInfo: {
      type: Object
    }
  },
  data () {
    return {
      input: '',
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      params: {
        id: 0,
        louce: '',
        louceid: '',
        glfjs: '',
        lccjr: '',
        lccjsj: '',
        ssjgbh: '',
        ssjgmc: '',
        jgbh: '',
        jgmc: '',
        fjh: '',
        fjid: '',
        fjlx: '',
        fjlxbm: '',
        cws: '',
        rzlr: '',
        fjcjr: '',
        fjcjsj: '',
        cwh: '',
        cwhid: '',
        cwyjg: '',
        cwcjr: '',
        cwcjsj: '',
        cwzt: '',
        name: '',
        sfzh: ''
      }
    }
  },
  created () {
    console.log('所选信息', this.selectInfo)
    this.params = this.selectInfo
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  methods: {
    close () {
      this.$emit('close')
    },
    getDate () {
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let f = date.getMinutes()
      f = f < 10 ? '0' + f : f
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (day >= 0 && day <= 9) {
        day = '0' + day
      }
      return `${year}-${month}-${day} ${h}:${f}:${s}`
      // return `${year}-${month}-${day}`
    },
    async submit () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-109465532
      try {
        if (this.params.louce === '') return this.$message.error('请输入楼层号')
        if (this.params.glfjs === '') return this.$message.error('请输入房间数')
        this.params.jgbh = this.loginInfo.jgbh
        this.params.jgmc = this.loginInfo.jgmc
        const response = await this.$http.post('/zhyyapi/zhyljcszcwgllcfjxg', this.params)
        console.log('楼层修改返回响应:', response.data)
        this.$message.success('修改成功')
        this.$parent.getList()
        this.$emit('close')
      } catch (error) {
        console.log('楼层修改失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1042px;
    height: 500px;

    .popup-title {
      width: 240px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 1042px;
      height: 350px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px 10px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          .info-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
            color: #01EEFD;
            font-size: 18px;

            .el-select {
              width: 800px;
              margin-left: 28px;
            }

            .el-input {
              width: 600px;
              margin-left: 28px;
            }

            .el-textarea {
              width: 800px;
              margin-left: 28px;
            }

            /deep/.el-textarea__inner {
              width: 800px;
              height: 104px;
              resize: none;
            }
          }

          .info-line:nth-child(3) {
            align-items: initial;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
